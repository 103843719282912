// @flow

/**
 * @param {string} targetId html tag id
 * showLoading Display loading on player
 * @returns {void}
 */
function showLoading(targetId: string) {
    let elem = document.getElementById(targetId);
    elem.innerHTML = '<div class="load-container"><div class="loader"></div></div>';
}
/**
 * @param {string} targetId html tag id
 * showLoading Display loading on player
 * @returns {void}
 */
function hideLoading(targetId: string) {
    let elem = document.getElementById(targetId);
    elem.innerHTML = '';
}


export { showLoading, hideLoading };