// @flow

import {PlayerManager} from './player-manager';
declare var __VERSION__: string;
declare var __NAME__: string;
// import * as Sentry from '@sentry/browser';


// try {
//     Sentry.init({
//       dsn: 'https://0221562bca5f47cf9f26a2e2d1f7be5a@errortracking.cdnmedia.tv/2',
//       debug: true,
//       release: `${__NAME__}@${__VERSION__}`,
//       attachStacktrace: true,
//       environment: 'production'
//       // initialScope: {
//       //   tags: {config: JSON.stringify(config)}
//       // },
//       // beforeSend(event) {
//       //   event.breadcrumbs = event.breadcrumbs.filter(b => b.level == 'error');
//       //   return event;
//       // }
//     });
//   } catch (e) {
//     console.log('Sentry not loaded', e);
//   }

const VERSION = __VERSION__;
const NAME = __NAME__;

export {PlayerManager as IVCPlayer};
export {VERSION, NAME};
