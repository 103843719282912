// @flow
import {Error} from '../utils/errors';
/**
 * Render error
 * @param {string} targetId html tag id
 * @param {Error} message message
 * @param {string} image show image as background
 * @param {string} text show text message
 * @param {boolean} activeBlur activeBlur
 * @param {Date} publishDate publishDate
 * @returns {any} none
 */
function render(targetId: string, message?: Error, image?: string, text: string, activeBlur?: boolean, publishDate?: Date): void {
  const doc = document.getElementById(targetId);
  let html = '<div id="error-background"></div><div class="ivc-playkit error-view">';
  if(message !== Error.NOT_AVAILABLE_YET) {
    html += ``;
    html += `<div class="error-container">`;
    if (text != null && text.length) {
      try {
        text = window.translations['error'][text];
      } catch (e) {
        // do nothing
      }
      html += `<div class="error-title">${text}</div>`;
    }
    html += `</div>`;
    doc.innerHTML = html + '</div>';
  }else{
    html += `
      <div class="error-container">
        <div class="error-title">${window.translations['error']['next_available']}</div>
        
        <div class="countdown">
          <div class="item">
            <div id="days"></div>
            <label>${window.translations['error']['days']}</label>
          </div>
          <div class="item">
            <div id="hours"></div>
            <label>${window.translations['error']['hours']}</label>
          </div>
          <div class="item">
            <div id="minutes"></div>
            <label>${window.translations['error']['minutes']}</label>
          </div>
          <div class="item">
            <div id="seconds"></div>
            <label>${window.translations['error']['seconds']}</label>
          </div>
        </div>
        <div class="error-title" style="font-size: 15px; margin-top: 0px; padding-top: 0px; display: none;">${window.translations['error']['will_be_available']} 12/02/2005 14:35</div>
      </div>
    `;
    doc.innerHTML = html + '</div>';
    let countdownInterval = setInterval(() => countdown(publishDate, countdownInterval), 1000);
    countdown(publishDate, countdownInterval);
  }
  
  const backgroundElement = document.getElementById('error-background');
  backgroundElement.style.width = '100%';
  backgroundElement.style.height = '100%';
  if(activeBlur !== undefined && activeBlur)
    backgroundElement.style.filter = 'blur(13px)';
  if (image !== undefined && image) {
    backgroundElement.style.backgroundImage = `url('${image}')`;
    backgroundElement.style.backgroundSize = 'cover';
  } else {
    backgroundElement.style.backgroundImage = `none`;
  }
}

/**
 * countdown
 * @param {Date} date date
 * @param {any} countdownInterval countdownInterval
 * @returns {any} none
 */
function countdown(date: Date, countdownInterval: any): void {
  const targetDate = date // Your target date
  const now = new Date();
  const timeLeft = targetDate.getTime() - now.getTime();

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  document.getElementById("days").innerHTML = String(days).padStart(2, '0');
  document.getElementById("hours").innerHTML = String(hours).padStart(2, '0');
  document.getElementById("minutes").innerHTML = String(minutes).padStart(2, '0');
  document.getElementById("seconds").innerHTML = String(seconds).padStart(2, '0');

  if (timeLeft < 0) {
    clearInterval(countdownInterval);
    document.getElementById("days").innerHTML = "00";
    document.getElementById("hours").innerHTML = "00";
    document.getElementById("minutes").innerHTML = "00";
    document.getElementById("seconds").innerHTML = "00";
  }
}

export {render};
