// @flow
import {providerUrl} from './config';
import {VideoMeta} from './videoMeta';
import {Logger} from './logger';

class APIProvider {
  isLive: boolean = false;
  isVod: boolean = false;
  embed: string;
  id: string;
  user: String;

  constructor(config: any) {
    this.config = config;
    this.logger = new Logger('JSONProvider');
    this.providerUrl = this.config.providerUrl !== undefined && this.config.providerUrl !== null ? this.config.providerUrl : providerUrl;
    this.embed = this.config.embed;
    this.defaultJSON = this.config.defaultJSON;
    if (this.embed.length == 8) {
      this.isLive = true;
      this.isVod = false;
    }
    if (this.embed.length == 10) {
      this.isLive = false;
      this.isVod = true;
      this.id = this.config.id;
    }
  }

  async init() {
    try {
      if (this.isVod) return await this.decodeVOD();
      if (this.isLive) return await this.decodeLIVE();
      throw new Error('404 not found');
    } catch (e) {
      return e;
    }
  }

  async decodeLIVE() {
    try {
      let result: VideoMeta = {};
      result['customization'] = {checkAdBlock: true};
      result['branding'] = {src: '', url: ''};
      result.isLive = true;
      result.isActive = true;

      //const data = await Http.execute(`${this.providerUrl}/definition/config/${videoEnc}.json`, null, 'GET');

      const regex = /^(https?|http):\/\/[.a-z]+[.a-z0-9-]+/gm;
      let data = {
        src: 'http://live10.cdnmedia.tv/telemedellintvlive/smil:dvrlive.smil/playlist.m3u8'
      };
      result.urls = {
        hls: data.src.replace('.f4m', '.m3u8').replace(regex, this.defaultJSON.urls[0]).replace('http:', 'https:')
      };
      try {
        const regStream = /live\/smil:?(.*)\.smil\/playlist|live\/(.*)\.stream\/playlist|live\/(.*)\/playlist/gm;
        let stream = regStream.exec(result.urls.hls)[1];
        result.streamNameLive = stream;
      } catch (e) {
        //do nothing
      }

      result.isDvr = false;
      console.log(result.urls.hls);
      if (result.urls.hls.indexOf('dvr') > -1) {
        result.isDvr = true;
        result.urls.hls = result.urls.hls + '?DVR';
      }
      result.basics = {
          title: ''
      };
      result.geoBlocking = [];
      result.allowedDomains =[];
      result.images = { principal: null }

      return result;
    } catch (e) {
      console.log(e);
    }
  }

  async decodeVOD() {
    try {
      let result: VideoMeta = {};
      result['customization'] = {checkAdBlock: true};
      result['branding'] = {src: '', url: ''};
      result.isLive = true;
      result.isActive = true;

      //const data = await Http.execute(`${this.providerUrl}/definition/config/${videoEnc}.json`, null, 'GET');

      const regex = /^(https?|http):\/\/[.a-z]+[.a-z0-9-]+/gm;
      let data = {
        src: 'http://live10.cdnmedia.tv/telemedellintvlive/smil:dvrlive.smil/playlist.m3u8'
      };
      result.urls = {
        hls: data.src.replace('.f4m', '.m3u8').replace(regex, this.defaultJSON.urls[0]).replace('http:', 'https:')
      };
      try {
        const regStream = /live\/smil:?(.*)\.smil\/playlist|live\/(.*)\.stream\/playlist|live\/(.*)\/playlist/gm;
        let stream = regStream.exec(result.urls.hls)[1];
        result.streamNameLive = stream;
      } catch (e) {
        //do nothing
      }

      result.isDvr = false;
      console.log(result.urls.hls);
      if (result.urls.hls.indexOf('dvr') > -1) {
        result.isDvr = true;
        result.urls.hls = result.urls.hls + '?DVR';
      }
      result.basics = {
          title: ''
      };
      result.geoBlocking = [];
      result.allowedDomains =[];
      result.images = { principal: null }

      return result;
    } catch (e) {
      console.log(e);
    }
  }
}

export {APIProvider};
