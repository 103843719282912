// @flow

/**
 * Render error
 * @param {string} targetId html tag id
 * @param {string} image show image as background
 * @param {string} text show text message
 * @param {string} icon icon to show
 * @returns {any} none
 */
function render(targetId: string, image?: string, text: string, icon?: string): void {
  const doc = document.getElementById(targetId);
  if (image !== undefined && image) {
    doc.style.backgroundImage = `url('${image}')`;
    doc.style.backgroundSize = 'cover';
  } else {
    doc.style.backgroundImage = `none`;
  }
  let html = `<div class="ivc-playkit error-view">`;
  if (icon != null && icon.length) {
    html += `
      <div class="error-icon"><img src="${icon}"></div>
  `;
  }
  if (text != null && text.length) {
    try {
      text = window.translations['error'][text];
    } catch (e) {
      // do nothing
    }
    html += `
      <div class="error-text">${text}</div>
  `;
  }
  html += `</div>`;
  doc.innerHTML = html;
}

export {render};
