// @flow
import {Logger} from './logger';
import minifier from 'string-minify';

class ImaXMLGenerator {
  logger: Logger;
  config: any;

  constructor(config: any) {
    this.logger = new Logger('IMAGenerator');
    this.config = config;
  }

  vast(mediaSrc: string, clickTarget?: string, skipOffsetSeconds?: number): string {
    const skip = skipOffsetSeconds !== undefined && skipOffsetSeconds > 0 ? `skipoffset="${this.secondsToTime(skipOffsetSeconds)}"` : '';
    const click =
      clickTarget !== undefined && clickTarget
        ? `
    <VideoClicks>
        <ClickThrough id="GDFP">
            <![CDATA[${clickTarget}]]>
        </ClickThrough>
    </VideoClicks>
    `
        : '';
    const result = `
    <VAST xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="vast.xsd" version="3.0">
    <Ad id="${this.getRandomNumberId(10)}">
        <InLine>
            <AdSystem>GDFP</AdSystem>
            <AdTitle></AdTitle>
            <Description></Description>
            <Creatives>
                <Creative id="${this.getRandomNumberId(10)}" sequence="1">
                    <Linear ${skip}>
                       ${click}
                        <MediaFiles>
                            <MediaFile id="GDFP" delivery="progressive" width="1280" height="720" type="video/mp4" bitrate="450" scalable="true" maintainAspectRatio="true">
                                <![CDATA[${mediaSrc}]]>
                            </MediaFile>
                        </MediaFiles>
                    </Linear>
                </Creative>
            </Creatives>
        </InLine>
    </Ad>
    </VAST>
    `;
    return minifier(result);
  }

  secondsToTime(seconds) {
    return [parseInt(seconds / 60 / 60), parseInt((seconds / 60) % 60), parseInt(seconds % 60)].join(':').replace(/\b(\d)\b/g, '0$1');
  }

  getRandomNumberId(length) {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
  }
}

export {ImaXMLGenerator};
