// @flow

/**
 * The Data plugin errors.
 * @type {Object}
 */
const Error: {[error: string]: object} = {
  GEO_BLOCKING: {text: 'geo_blocked', icon: 'public_white_24dp.svg'},
  DOMAIN_BLOCKED: {text: 'domain_blocked', icon: 'http_white_24dp.svg'},
  NOT_FOUND: {text: 'not_found', icon: 'error_white_24dp.svg'},
  VIDEO_NOT_ACTIVE: {text: 'not_active', icon: 'visibility_off_white_24dp.svg'},
  ADBLOCK_ACTIVATED: {text: 'ad_blocked', icon: 'visibility_off_white_24dp.svg'}
};

export {Error};
