// @flow

import {Http} from './http';
import {providerUrl as _providerUrl, storageUrl, vodUrl} from './config';

/**
 * defaultJSON
 * @param {string} user user
 * @param {any} playerConfig playerConfig
 * @param {string} providerUrl providerUrl
 * @param {string} stream stream
 * @returns {void} defaultjSON data
 */
export default async function defaultJSON(user: string, playerConfig: any, providerUrl?: string, stream?: string) {
  let local_providerUrl = providerUrl !== undefined && providerUrl !== null ? providerUrl : _providerUrl;
  const vodUrls = playerConfig.vodUrl !== undefined && playerConfig.vodUrl ? playerConfig.vodUrl : vodUrl;
  const stirageUrls = playerConfig.storageUrl !== undefined && playerConfig.storageUrl ? playerConfig.storageUrl : storageUrl;
  let data = {
    urls: [],
    vod: vodUrls,
    storage: [stirageUrls]
  };
  if (stream !== undefined && stream) {
    try {
      const dataUserJson = await Http.execute(`${local_providerUrl}/definition/fileover/${user}.json`, null, 'GET');
      if (dataUserJson[stream] !== undefined && dataUserJson[stream]) {
        // data.urls = dataUserJson[stream].filter((item: string) => item !== 'https://liveingesta119.cdnmedia.tv' && item !== 'https://liveingesta319.cdnmedia.tv' && item !== 'https://liveingesta361.cdnmedia.tv');
        // data.urls = data.urls.map(s => s.replace('https://liveingesta118.cdnmedia.tv', 'https://liveingesta119.cdnmedia.tv'));
        data.urls = dataUserJson[stream];
      } else {
        throw new Error('stream not exists');
      }
    } catch (e) {
      const errorDataJSON = await Http.execute(`${local_providerUrl}/definition/fileover/default.json`, null, 'GET');
      let d = errorDataJSON[user] !== undefined ? errorDataJSON[user] : errorDataJSON.default;
      data.urls = d.urls;
    }
  } else {
    const errorDataJSON = await Http.execute(`${local_providerUrl}/definition/fileover/default.json`, null, 'GET');
    let d = errorDataJSON[user] !== undefined ? errorDataJSON[user] : errorDataJSON.default;
    data.urls = d.urls;
  }
  return data;
}
