// @flow
import {PlayerManager} from '../player-manager';
import scriptjs from 'scriptjs';
import {ImaXMLGenerator} from '../utils/imaGenerator';
import minifier from 'string-minify';
import {Error} from '../utils/errors';

export default (playerManager: PlayerManager): Promise<any> => {
  var no_adblock = undefined;

  if (playerManager.videoMeta.ads.vast.url || playerManager.videoMeta.ads.ivc.pre_roll.src || playerManager.videoMeta.ads.ivc.mid_roll.src || playerManager.videoMeta.ads.ivc.pos_roll.src) {
    return new Promise(resolve => {
      new Promise(resolve => {
        if (playerManager.videoMeta.customization.checkAdBlock) {
          scriptjs(`https://imasdk.googleapis.com/js/sdkloader/ima3.js`, () => {
            if (window.ima !== undefined) no_adblock = true;
            resolve();
          });
        } else {
          resolve(playerManager.playerConfig);
        }
      }).then(() => {
        if (no_adblock === undefined && playerManager.videoMeta.customization.checkAdBlock) {
          playerManager.error(
            Error.ADBLOCK_ACTIVATED,
            playerManager.videoMeta.images.principal,
            Error.ADBLOCK_ACTIVATED.text,
            true
          );
          return;
        }
        scriptjs(`${playerManager.assetsCDN}/playkit-ima.js`, () => {
          if (playerManager.videoMeta.ads.vast.url) {
            playerManager.playerConfig.plugins['ima'] = {
              adTagUrl: playerManager.videoMeta.ads.vast.url
            };
            resolve(playerManager.playerConfig);
          } else {
            let generator = new ImaXMLGenerator(playerManager);
            let existsAds = false;
            let adBreaks = {
              pre: [],
              mid: [],
              post: []
            };
            if (
              playerManager.videoMeta.ads.ivc.pre_roll.src &&
              playerManager.videoMeta.ads.ivc.pre_roll.src != 'false' &&
              playerManager.videoMeta.ads.ivc.pre_roll.src != ''
            ) {
              try {
                if (playerManager.videoMeta.ads.ivc.pre_roll.src.indexOf('.f4m') > -1) {
                  let pre_roll = playerManager.videoMeta.ads.ivc.pre_roll;
                  pre_roll.src = pre_roll.src.substring(pre_roll.src.indexOf('streams=') + 8, pre_roll.src.indexOf('.MP4'));
                  pre_roll.src = `${playerManager.defaultJSON.vod[0].replace('http://', 'https://')}/video/${playerManager.videoMeta.user}/${
                    pre_roll.src
                  }.MP4`;
                  pre_roll.skipOffset = pre_roll.skipOffset == 0 ? 0.1 : pre_roll.skipOffset;
                }
              } catch (e) {
                // do nothing
              }
              adBreaks.pre.push(
                minifier(`<vmap:AdBreak timeOffset="start" breakType="linear" breakId="preroll-${getRandomNumberId(5)}">
              <vmap:AdSource id="preroll-ad-${getRandomNumberId(10)}">
               <vmap:VASTAdData>${generator.vast(
                 playerManager.videoMeta.ads.ivc.pre_roll.src,
                 null,
                 playerManager.videoMeta.ads.ivc.pre_roll.skipOffset
               )}</vmap:VASTAdData>
              </vmap:AdSource>
             </vmap:AdBreak>`)
              );
              existsAds = true;
            }
            if (
              playerManager.videoMeta.ads.ivc.mid_roll.src &&
              playerManager.videoMeta.ads.ivc.mid_roll.src != 'false' &&
              playerManager.videoMeta.ads.ivc.mid_roll.src != ''
            ) {
              try {
                if (playerManager.videoMeta.ads.ivc.mid_roll.src.indexOf('.f4m') > -1) {
                  let mid_roll = playerManager.videoMeta.ads.ivc.mid_roll;
                  mid_roll.src = mid_roll.src.substring(mid_roll.src.indexOf('streams=') + 8, mid_roll.src.indexOf('.MP4'));
                  mid_roll.src = `${playerManager.defaultJSON.vod[0].replace('http://', 'https://')}/video/${playerManager.videoMeta.user}/${
                    mid_roll.src
                  }.MP4`;
                  mid_roll.skipOffset = mid_roll.skipOffset == 0 ? 0.1 : mid_roll.skipOffset;
                }
              } catch (e) {
                // do nothing
              }
              if (!playerManager.videoMeta.isLive && playerManager.videoMeta.duration > 1) {
                const duration =
                  playerManager.videoMeta.duration !== undefined && playerManager.videoMeta.duration ? playerManager.videoMeta.duration : 0;
                adBreaks.mid.push(
                  minifier(`<vmap:AdBreak timeOffset="${secondsToTime(
                    Math.floor(duration / 2)
                  )}" breakType="linear" breakId="midroll-${getRandomNumberId(5)}">
                <vmap:AdSource id="preroll-ad-${getRandomNumberId(10)}">
                 <vmap:VASTAdData>${generator.vast(
                   playerManager.videoMeta.ads.ivc.mid_roll.src,
                   null,
                   playerManager.videoMeta.ads.ivc.mid_roll.skipOffset
                 )}</vmap:VASTAdData>
                </vmap:AdSource>
               </vmap:AdBreak>`)
                );
                existsAds = true;
              }
            }
            if (
              playerManager.videoMeta.ads.ivc.pos_roll.src &&
              playerManager.videoMeta.ads.ivc.pos_roll.src != 'false' &&
              playerManager.videoMeta.ads.ivc.pos_roll.src != ''
            ) {
              try {
                if (playerManager.videoMeta.ads.ivc.pos_roll.src.indexOf('.f4m') > -1) {
                  let pos_roll = playerManager.videoMeta.ads.ivc.pos_roll;
                  pos_roll.src = pos_roll.src.substring(pos_roll.src.indexOf('streams=') + 8, pos_roll.src.indexOf('.MP4'));
                  pos_roll.src = `${playerManager.defaultJSON.vod[0].replace('http://', 'https://')}/video/${playerManager.videoMeta.user}/${
                    pos_roll.src
                  }.MP4`;
                  pos_roll.skipOffset = pos_roll.skipOffset == 0 ? 0.1 : pos_roll.skipOffset;
                }
              } catch (e) {
                // do nothing
              }
              if (!playerManager.videoMeta.isLive) {
                adBreaks.mid.push(
                  minifier(`<vmap:AdBreak timeOffset="end" breakType="linear" breakId="midroll-${getRandomNumberId(5)}">
                <vmap:AdSource id="preroll-ad-${getRandomNumberId(10)}">
                 <vmap:VASTAdData>${generator.vast(
                   playerManager.videoMeta.ads.ivc.pos_roll.src,
                   null,
                   playerManager.videoMeta.ads.ivc.pos_roll.skipOffset
                 )}</vmap:VASTAdData>
                </vmap:AdSource>
               </vmap:AdBreak>`)
                );
                existsAds = true;
              }
            }
            if (existsAds) {
              const adTagUrl = minifier(`
            <vmap:VMAP xmlns:vmap="http://www.iab.net/videosuite/vmap" version="1.0">
            ${adBreaks.pre ? adBreaks.pre.join('') : ''}
            ${adBreaks.mid ? adBreaks.mid.join('') : ''}
            ${adBreaks.post ? adBreaks.post.join('') : ''}
            </vmap:VMAP>`);
              playerManager.playerConfig.plugins['ima'] = {
                adsResponse: adTagUrl
              };
            } else {
              playerManager.playerConfig.plugins['ima'] = {
                adsResponse: ''
              };
            }
            resolve(playerManager.playerConfig);
          }
        });
      });
    });
  } else {
    delete playerManager.playerConfig.plugins['ima'];
    return Promise.resolve(playerManager.playerConfig);
  }
};

/**
 * getRandomNumberId
 * @param {number} length length
 * @returns {number} random result
 */
function getRandomNumberId(length: number): number {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
}

/**
 * secondsToTime
 * @param {number} seconds duration
 * @returns {string} random result
 */
function secondsToTime(seconds: number): string {
  return [parseInt(seconds / 60 / 60), parseInt((seconds / 60) % 60), parseInt(seconds % 60)].join(':').replace(/\b(\d)\b/g, '0$1');
}
