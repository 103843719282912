import scriptjs from 'scriptjs';

/**
 * Internal load for script
 * @param {string} url cdn url to resolve
 * @returns {Promise<any>} Promise of execution
 */
function load(url) {
  return new Promise(resolve => {
    scriptjs(url, resolve);
  });
}

/**
 * generate list of scripts
 * @param {string} cdn cdn url to resolve
 * @returns {Array} array of scripts
 */
function loadScrips(cdn) {
  let scripts = [
    // `http://demo.multistream.tv/kplayer/js/kaltura-ovp-player.js`,
    `${cdn}/ivc-ovp-player.js`,
    `${cdn}/playkit-airplay.js`,
    `${cdn}/playkit-cast-sender.js`,
    `${cdn}/playkit-ivc-chat.js`,
    `${cdn}/playkit-ivc-title.js`,
    `${cdn}/playkit-info.js`
  ];
  return scripts;
}

/**
 * load scripts
 * @param {string} cdn url of cdn to resolve
 * @returns {*} nothing
 */
async function scripts(cdn) {
  for (let script of loadScrips(cdn)) {
    await load(script);
  }
  return;
}

export {scripts, load};
