// @flow

module.exports.defaultConfig = {
  log: {
    level: 'OFF',
    playerVersion: false
  },
  targetId: null,
  provider: {},
  sources: {},
  playback: {
    playsinline: true,
    preferNative: {
      hls: false,
      dash: false
    },
    volume: 1,
    preload: 'none',
    autoplay: false,
    allowMutedAutoPlay: true,
    muted: false,
    pictureInPicture: true,
    inBrowserFullscreen: false,
    options: {
      idAudio: true,
      html5: {
        hls: {},
        native: {}
      }
    },
    streamPriority: [
      {
        engine: 'html5',
        format: 'hls'
      },
      {
        engine: 'html5',
        format: 'progressive'
      }
    ]
  },
  abr: {
    enabled: true,
    fpsDroppedFramesInterval: 5000,
    fpsDroppedMonitoringThreshold: 0.3,
    capLevelOnFPSDrop: true,
    capLevelToPlayerSize: true,
    restrictions: {
      minBitrate: 0,
      maxBitrate: Infinity
    }
  },
  plugins: {
    airplay: {}
  },
  cast: {
    receiverApplicationId: '174E2125',
    advertising: {
      vast: false
    }
  },
  text: {
    useNativeTextTrack: false,
    enableCEA708Captions: true,
    captionsTextTrack1Label: 'Español',
    captionsTextTrack1LanguageCode: 'es',
    captionsTextTrack2Label: 'English',
    captionsTextTrack2LanguageCode: 'en'
  },
  ui: {
    hoverTimeout: 1000,
    view: 'inherit',
    components: {
      watermark: {},
      share: {},
      seekbar: {
        thumbsWidth: 120,
        ivc: {
          cdn: null,
          user: null,
          id: null,
          isLive: false,
          isDvr: false
        },
        width: 120,
        height: 68,
        timeSeconds: 5,
        itemByLine: 10,
        lines: 10,
        viewWidth: 192,
        viewHeight: 108
      },
      sidePanels: {
        verticalSizes: {
          min: 264,
          max: 264,
          ratio: 0.33
        },
        horizontalSizes: {
          min: 144,
          max: 288,
          ratio: 0.5
        }
      }
    }
  },
  network: {
    maxStaleLevelReloads: 10
  },
  InstantVideoCloud: {}
};

module.exports.providerUrl = 'https://json.instantvideocloud.net';
module.exports.assetsCDN = 'https://player.instantvideocloud.net';
module.exports.defaultAllowedDomains = ['ivc.cdnmedia.tv', 'devivc2.cdnmedia.tv', 'player.instantvideocloud.tv'];
module.exports.geoIpReferrer = 'https://player.instantvideocloud.net';
module.exports.defaultUsersIvcAnalytics = [];
module.exports.realtimeUrl = 'https://rltm24.cdnmedia.tv';
module.exports.chatUrl = 'https://streamvdn.cdnmedia.tv/commentbox/index.php';
module.exports.geoIpRealtime = 'https://rltm24.cdnmedia.tv/ip';
module.exports.analyticsUrl = 'https://analytics.cdnmedia.tv/';
module.exports.storageUrl = 'https://storage.cdnmedia.tv';
module.exports.vodUrl = ['http://vodingesta1083.cdnmedia.tv', 'http://vodingesta1084.cdnmedia.tv/'];
module.exports.whiteListDomains = ['localhost', 'capacitor', 'ivc.cdnmedia.tv', 'devivc2.cdnmedia.tv'];
module.exports.defaultInputConfig = {
  embed: null,
  id: null,
  startTime: -1,
  assetsCDN: null,
  defaultJSON: null,
  providerUrl: null,
  providerType: 'ivcjsons',
  log: 'OFF',
  videoMeta: null
};

module.exports.hlsLiveConfig = {
  debug: false,
  liveSyncDurationCount: 5,
  maxBufferLength: 32,
  initialLiveManifestSize: 1,
  maxLoadingDelay: 4,
  enableWorker: false,
  abrBandWidthFactor: 0.5,
  capLevelToPlayerSize: true,

  manifestLoadingTimeOut: 7000,
  manifestLoadingMaxRetry: 1,
  manifestLoadingRetryDelay: 1000,
  levelLoadingTimeOut: 7000,
  levelLoadingMaxRetry: 0,
  levelLoadingRetryDelay: 1000
};
module.exports.hlsDvrConfig = {
  debug: false,
  maxLoadingDelay: 2,
  enableWorker: false,
  abrBandWidthFactor: 0.5,
  capLevelToPlayerSize: true,

  liveSyncDurationCount: 3,
  initialLiveManifestSize: 2,
  maxBufferLength: 16,
  maxMaxBufferLength: 8,
  maxBufferSize: 60 * 1000 * 1000,

  manifestLoadingTimeOut: 7000,
  manifestLoadingMaxRetry: 1,
  manifestLoadingRetryDelay: 1000,
  levelLoadingTimeOut: 7000,
  levelLoadingMaxRetry: 0,
  levelLoadingRetryDelay: 1000,
};
module.exports.hlsVodConfig = {
  enableWorker: false,
  maxBufferLength: 6,
  maxStarvationDelay: 3,
  maxBufferSize: 6 * 1000 * 1000,
  abrBandWidthFactor: 0.5,

  manifestLoadingTimeOut: 5000,
  manifestLoadingMaxRetry: 1,
  manifestLoadingRetryDelay: 1000,
  levelLoadingTimeOut: 5000,
  levelLoadingMaxRetry: 0,
  levelLoadingRetryDelay: 1000
};
