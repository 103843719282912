// @flow

export class Logger {
  name: string;
  constructor(name) {
    this.name = name;
  }

  debug(...args) {
    console.debug(`[IVCPlayer] [${this.name}] `, ...args);
  }

  error(...args) {
    console.debug(`[IVCPlayer] [${this.name}] `, ...args);
  }
}
