// @flow
import {PlayerManager} from '../player-manager';
import {load} from '../utils/scripts';

/**
 *
 * @param {PlayerManager} playerManager instance of player manager
 * @returns {Object} playerConfig
 */
async function GoogleAnalytics(playerManager: PlayerManager) {
  try {
    if (playerManager.videoMeta.analytics.google.key !== undefined && playerManager.videoMeta.analytics.google.key.length) {
      try {
        await load(`${playerManager.assetsCDN}/playkit-google-analytics.js`);
        playerManager.playerConfig.plugins['googleAnalytics'] = {
          trackingId: playerManager.videoMeta.analytics.google.key,
          title: playerManager.videoMeta.basics.title,
          playerId: playerManager.configInput.embed,
          id: playerManager.configInput.id || null,
          isLive: playerManager.videoMeta.isLive
        };
      } catch (e) {
        // do nothing
      }
    } else {
      delete playerManager.playerConfig.plugins['googleAnalytics'];
    }
  } catch (e) {
    // do nothing
    delete playerManager.playerConfig.plugins['googleAnalytics'];
  }
  return playerManager.playerConfig;
}

/**
 *
 * @param {PlayerManager} playerManager instance of player manager
 * @returns {Object} playerConfig
 */
async function KantarAnalytics(playerManager: PlayerManager) {
  try {
    if (
      playerManager.videoMeta.analytics.kantarmedia.kantarET !== undefined &&
      playerManager.videoMeta.analytics.kantarmedia.kantarET !== 'false' &&
      playerManager.videoMeta.analytics.kantarmedia.kantarET !== 'undefined' &&
      playerManager.videoMeta.analytics.kantarmedia.kantarET.length &&
      playerManager.videoMeta.analytics.kantarmedia.kantarID !== undefined &&
      playerManager.videoMeta.analytics.kantarmedia.kantarID !== 'false' &&
      playerManager.videoMeta.analytics.kantarmedia.kantarID !== 'undefined' &&
      playerManager.videoMeta.analytics.kantarmedia.kantarID.length
    ) {
      try {
        await load(`${playerManager.assetsCDN}/playkit-kantar.js`);
        playerManager.playerConfig.plugins['kantar'] = {
          kantarET: playerManager.videoMeta.analytics.kantarmedia.kantarET,
          kantarID: playerManager.videoMeta.analytics.kantarmedia.kantarID,
          title: playerManager.videoMeta.basics.title,
          playerId: playerManager.configInput.embed,
          id: playerManager.configInput.id || null,
          isLive: playerManager.videoMeta.isLive,
          assetsCDN: playerManager.assetsCDN
        };
      } catch (e) {
        // do nothing
        playerManager.playerConfig.plugins['kantar'];
      }
    } else {
      delete playerManager.playerConfig.plugins['kantar'];
    }
  } catch (e) {
    // do nothing
  }
  return playerManager.playerConfig;
}

/**
 *
 * @param {PlayerManager} playerManager instance of player manager
 * @returns {Object} playerConfig
 */
async function IvcAnalytics(playerManager: PlayerManager) {
  try {
    if (playerManager.defaultUsersIvcAnalytics.findIndex(u => u == playerManager.videoMeta.user) > -1) {
      await load(`${playerManager.assetsCDN}/playkit-ivc-analytics.js`);
      playerManager.playerConfig.plugins['ivcAnalytics'] = {
        title: playerManager.videoMeta.basics.title,
        playerId: playerManager.configInput.embed,
        id: playerManager.configInput.id || null,
        isLive: playerManager.videoMeta.isLive,
        assetsCDN: playerManager.assetsCDN,
        user: playerManager.videoMeta.user,
        analyticsUrl: playerManager.analyticsUrl
      };
    } else {
      delete playerManager.playerConfig.plugins['ivcAnalytics'];
    }
    // }
  } catch (e) {
    // do nothung
    delete playerManager.playerConfig.plugins['ivcAnalytics'];
  }
  return playerManager.playerConfig;
}

/**
 *
 * @param {PlayerManager} playerManager instance of player manager
 * @returns {Object} playerConfig
 */
async function IvcRealtimeAnalytics(playerManager: PlayerManager) {
  try {
    if (
      playerManager.videoMeta.analytics.realtime !== undefined &&
      playerManager.videoMeta.analytics.realtime.disabled !== undefined &&
      !playerManager.videoMeta.analytics.realtime.disabled
    ) {
      try {
        await load(`${playerManager.assetsCDN}/playkit-ivc-realtime.js`);

        let streamName;
        if (playerManager.videoMeta.isLive) {
          const regex = /smil:(.*).smil/gm;
          const stream = regex.exec(playerManager.videoMeta.urls.hls);
          streamName = stream[1];
        }

        playerManager.playerConfig.plugins['IvcRealtime'] = {
          title: playerManager.videoMeta.basics.title,
          playerId: playerManager.configInput.embed,
          id: playerManager.configInput.id || streamName,
          isLive: playerManager.videoMeta.isLive,
          assetsCDN: playerManager.assetsCDN,
          user: playerManager.videoMeta.user,
          realtimeUrl: playerManager.realtimeUrl,
          geoIpRealtime: playerManager.geoIpRealtime
        };
      } catch (e) {
        // do nothing
        delete playerManager.playerConfig.plugins['IvcRealtime'];
      }
    } else {
      delete playerManager.playerConfig.plugins['IvcRealtime'];
    }
  } catch (e) {
    // do nothing
    delete playerManager.playerConfig.plugins['IvcRealtime'];
  }
  return playerManager.playerConfig;
}

export default async (playerManager: PlayerManager) => {
  playerManager.playerConfig = await GoogleAnalytics(playerManager);
  playerManager.playerConfig = await KantarAnalytics(playerManager);
  playerManager.playerConfig = await IvcAnalytics(playerManager);
  playerManager.playerConfig = await IvcRealtimeAnalytics(playerManager);
  return playerManager.playerConfig;
};
