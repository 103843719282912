// @flow
import {PlayerManager} from '../player-manager';
import insertCss from 'insert-css';
/**
 *
 * @param {PlayerManager} playerManager instance of player manager
 * @returns {void} void
 */
async function customizePlayer(playerManager: PlayerManager) {
  try {
    if (playerManager.videoMeta.customization.color.value) {
      const c = playerManager.videoMeta.customization.color.value;
      const a = playerManager.videoMeta.customization.color.alpha == 0 ? 0.56 : playerManager.videoMeta.customization.color.alpha;
      // const a = 1;
      const rgba = 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + a + ')';
      const rgbaButtons = 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + a + ')';
      const rgbaLAlpha = 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + a + ')';
      const rgbaPlayLoading = 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + a + ')';
      const targetId = playerManager.playerConfig.targetId;
      insertCss(`
        /* Customize CSS --> */
        #${targetId} .playkit-center-playback-controls .playkit-control-button, #${targetId} .playkit-pre-playback-play-overlay .playkit-pre-playback-play-button, #${targetId} .playkit-pre-loading-play-button {background-color: ${rgbaPlayLoading} !important}
        #${targetId} .playkit-player .playkit-seek-bar .playkit-progress-bar {background-color: ${rgba} !important}
        #${targetId} .playkit-player .playkit-seek-bar .playkit-progress-bar .playkit-progress, .playkit-volume-control-bar .playkit-progress{background-color: ${rgbaLAlpha} !important}
        #${targetId} .playkit-button-rounded, #${targetId} .playkit-live-tag-small, #${targetId} .playkit-dvr-tag, #${targetId} .playkit-live-tag, #${targetId} .playkit-dvr-tag-rounded, #${targetId} .info-btn {border-color:  ${rgbaButtons} !important}
        #${targetId} .playkit-frame-preview .playkit-frame-preview-top-border, #${targetId} .playkit-frame-preview .playkit-frame-preview-bottom-border{border-color:  ${rgba} !important}
        #${targetId} .playkit-pre-loading-play-button svg path{fill: ${rgbaPlayLoading};}
        #${targetId}.playkit-pre-loading-play-overlay .playkit-pre-loading-play-button{background-color: ${rgbaPlayLoading};}
        #${targetId} .playkit-active-rounded{background: ${rgba} !important;}
        #${targetId} .playkit-custom-overlay .playkit-message .playkit-loader-line:before{background-color: ${rgba} !important;}
        #${targetId} .playkit-tv .playkit-focusCtrl.playkit-focused{background-color: ${rgba} !important;}
        #${targetId} .playkit-button-apply.playkit-focused{background-color: ${rgba} !important;}
        #${targetId} .playkit-captionsItem.playkit-focused{background-color: ${rgba} !important;}
        #${targetId} .playkit-capt-style-ctrl.playkit-focused,  #${targetId} .playkit-scale-capt-ctrl.playkit-focused{border-color: ${rgba} !important;}
        `);
    }
  } catch (e) {
    // do nothing
  }
}

export {customizePlayer};
